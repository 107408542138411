import { createSelector } from '@ngrx/store';

import { ControllerProtocolSettings, Element } from '@priva/next-model';

import { ControllersStateContainer } from './controllers.state';

export interface ControllerProtocolDetail extends Element {
    controllerProtocolSettings?: ControllerProtocolSettings;
}

const controllerProtocolMapping = (
    controller: Element,
    controllersProtocolSettings: ControllerProtocolSettings[],
): ControllerProtocolDetail => {
    const controllerProtocolSettings = controllersProtocolSettings.find((c) => c.id === controller.id);
    return { ...controller, controllerProtocolSettings };
};

export const selectControllersProtocolDetails = createSelector(
    (state: ControllersStateContainer) => state.controllers.controllers,
    (state: ControllersStateContainer) => state.controllers.controllersProtocolSettings,
    (
        controllers?: Element[],
        controllerProtocolSettings?: ControllerProtocolSettings[],
    ): ControllerProtocolDetail[] | undefined => {
        if (!controllers || !controllerProtocolSettings) {
            return undefined;
        }
        return controllers.map((controller) =>
            controllerProtocolMapping(controller, controllerProtocolSettings),
        );
    },
);
