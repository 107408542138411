import { createSelector } from '@ngrx/store';

import {
    ConfigurationChoice,
    ConfigurationTab,
    DatasheetBinding,
    DatasheetDetail,
    isController,
    isEquipment,
    LogicalDevice,
    LogicalDeviceDefinition,
    SystemComponent,
    toResourceKeyLogicalDeviceDefinition,
} from '@priva/next-model';

import { countOccurrences } from 'app/common/utils';
import { DatasheetStateContainer } from 'app/template/datasheet/state';
import { getActiveDatasheetDetails } from 'app/template/datasheet/state/datasheet.selectors';

import { SystemComponentStateContainer } from '../../state';
import { ConfigurationStateContainer } from './configuration.state';

const selectIsChanged = (state: ConfigurationStateContainer) =>
    state.configuration.isConfigurationChanged ||
    state.configuration.isCommunicationChanged ||
    state.values.isChanged ||
    state.connectivity.isChanged;

export const isChanged = createSelector(selectIsChanged, (isChanged?: boolean) => {
    return isChanged;
});

export interface LogicalDeviceBindingDetail {
    logicalDeviceDefinition: LogicalDeviceDefinition;
    datasheetBinding?: DatasheetBinding;
    datasheet?: DatasheetDetail;
    datasheetLogicalDevice?: LogicalDevice;
}

export interface LogicalDeviceDragDetail {
    datasheetLogicalDevice: LogicalDevice;
    datasheet: DatasheetDetail;
}

export const hasLogicalDeviceDefinitions = (state: ConfigurationStateContainer) =>
    state.configuration.logicalDeviceDefinitions.length > 0;
export const selectDatasheetBindings = (state: ConfigurationStateContainer) =>
    state.configuration.datasheetBindings;
export const selectDevicesNeeded = (state: ConfigurationStateContainer) => state.configuration.devicesNeeded;

const selectLogicalDeviceDefinitions = (state: ConfigurationStateContainer) =>
    state.configuration.logicalDeviceDefinitions;
const selectDatasheetDetails = (state: ConfigurationStateContainer & DatasheetStateContainer) =>
    state.datasheet.datasheets;
const selectActiveDatasheetIds = (state: ConfigurationStateContainer & DatasheetStateContainer) =>
    state.datasheet.activeDatasheetDetailIds;
export const activeConfiguration = (state: ConfigurationStateContainer) => state.configuration.active;
export const activeSystemComponent = (state: SystemComponentStateContainer & ConfigurationStateContainer) =>
    state.systemComponent.active;

function setDatasheetLinkedContext(
    datasheet: DatasheetDetail,
    datasheetBinding: DatasheetBinding,
    logicalDeviceDefinitions: LogicalDeviceDefinition[],
): DatasheetDetail {
    const context = [];
    const datasheetLogicalDevices = datasheet.logicalDevices.map((logicalDevice) => {
        const logicalDeviceBinding = datasheetBinding.logicalDeviceBindings.find(
            (ldb) => ldb.datasheetLogicalDeviceId === logicalDevice.id,
        );
        logicalDevice.isLinked = !!logicalDeviceBinding;
        if (logicalDeviceBinding) {
            const logicalDeviceDefinition = logicalDeviceDefinitions?.find(
                (ldd) => ldd.id === logicalDeviceBinding.profileDatapointId,
            );
            if (logicalDeviceDefinition) {
                context.push(toResourceKeyLogicalDeviceDefinition(logicalDeviceDefinition));
            }
        }
        return { ...logicalDevice };
    });
    datasheet.logicalDevices = datasheetLogicalDevices;
    datasheet.logicalDeviceDefinitionsContext = context;
    return datasheet;
}

function setDatasheetLogicalDevicesDisabledWhenBind(
    datasheets: DatasheetDetail[],
    datasheetBindings: DatasheetBinding[],
    logicalDeviceDefinitions: LogicalDeviceDefinition[],
) {
    datasheetBindings.forEach((datasheetBinding) => {
        const datasheet = getCorrespondingActiveDatasheet(datasheetBinding, datasheetBindings, datasheets);
        if (datasheet) {
            setDatasheetLinkedContext(datasheet, datasheetBinding, logicalDeviceDefinitions);
        }
    });
}

function getCorrespondingActiveDatasheet(
    datasheetBinding: DatasheetBinding,
    datasheetBindings: DatasheetBinding[],
    datasheets: DatasheetDetail[],
): DatasheetDetail {
    const datasheetBindingIndex = datasheetBindings.findIndex((db) => db.id === datasheetBinding.id);
    const countDatasheetOccurences = countOccurrences(datasheetBindings.map((db) => db.datasheetId));
    const datasheetNumber = countDatasheetOccurences[datasheetBindingIndex];
    return datasheets.find(
        (d) => d.id === datasheetBinding.datasheetId && d.datasheetNumber === datasheetNumber,
    );
}

export const selectActiveDatasheetDetails = createSelector(
    selectDatasheetDetails,
    selectActiveDatasheetIds,
    selectDatasheetBindings,
    selectLogicalDeviceDefinitions,
    (
        datasheets: DatasheetDetail[],
        activeDatasheetIds: string[],
        datasheetBindings: DatasheetBinding[],
        logicalDeviceDefinitions: LogicalDeviceDefinition[],
    ): DatasheetDetail[] => {
        if (!datasheets || !activeDatasheetIds || !datasheetBindings) {
            return undefined;
        }
        if (activeDatasheetIds.some((id) => !datasheets.some((d) => d.id === id))) {
            return undefined;
        }
        const activeDatasheetDetails = getActiveDatasheetDetails(datasheets, activeDatasheetIds);
        setDatasheetLogicalDevicesDisabledWhenBind(
            activeDatasheetDetails,
            datasheetBindings,
            logicalDeviceDefinitions,
        );
        return activeDatasheetDetails;
    },
);

export const selectLogicalDeviceBindingDetails = createSelector(
    selectLogicalDeviceDefinitions,
    selectDatasheetBindings,
    selectActiveDatasheetDetails,
    (
        logicalDeviceDefinitions: LogicalDeviceDefinition[],
        datasheetBindings: DatasheetBinding[],
        activeDatasheetDetails: DatasheetDetail[],
    ): LogicalDeviceBindingDetail[] => {
        if (!logicalDeviceDefinitions || !datasheetBindings || !activeDatasheetDetails) {
            return undefined;
        }

        return logicalDeviceDefinitions.map((logicalDeviceDefinition) => {
            const datasheetBinding = datasheetBindings.find((datasheetBindings) =>
                datasheetBindings.logicalDeviceBindings.some(
                    (db) => db.profileDatapointId === logicalDeviceDefinition.id,
                ),
            );
            let datasheet;
            let datasheetLogicalDevice;
            if (datasheetBinding) {
                datasheet = getCorrespondingActiveDatasheet(
                    datasheetBinding,
                    datasheetBindings,
                    activeDatasheetDetails,
                );
                if (datasheet) {
                    datasheetBinding.datasheetNumber = datasheet.datasheetNumber;
                    const logicalDeviceBinding = datasheetBinding?.logicalDeviceBindings.find(
                        (logicalDeviceBinding) =>
                            logicalDeviceBinding.profileDatapointId === logicalDeviceDefinition.id,
                    );

                    datasheetLogicalDevice = datasheet?.logicalDevices.find(
                        (logicalDevice) => logicalDevice.id === logicalDeviceBinding.datasheetLogicalDeviceId,
                    );
                }
            }

            const logicalDeviceBindingDetail: LogicalDeviceBindingDetail = {
                logicalDeviceDefinition,
                datasheetBinding,
                datasheet,
                datasheetLogicalDevice,
            };
            return logicalDeviceBindingDetail;
        });
    },
);

export const selectAvailableConfigurationTabs = createSelector(
    activeConfiguration,
    activeSystemComponent,
    (configurations: ConfigurationChoice[], systemComponent: SystemComponent): ConfigurationTab[] => {
        if (!configurations || !systemComponent) {
            return undefined;
        }
        const tabVisibilities: { [key in ConfigurationTab] } = {
            Configuration: configurations.length,
            Connectivity: isController(systemComponent),
            Communication: isEquipment(systemComponent),
            Values: configurations.length,
        };
        return Object.entries(tabVisibilities)
            .filter(([, isVisible]) => isVisible)
            .map(([tab]) => tab as ConfigurationTab);
    },
);
