import { createAction, props } from '@ngrx/store';

import { Patch, Prototype, ZoneDetail } from '@priva/next-model';

import { findZone } from '@next/design-system/topology';
import { createUndoableAction } from 'app/common/undo/undo-redo.functions';

import { ZoneActions } from '.';

export const patchZoneSuccess = createAction(
    '[Zone API Undoable] Patch some details of a zone (success)',
    props<Patch>(),
);

export const patchZone = createUndoableAction(
    '[Zone API Undoable] Patch some details of a zone',
    props<Patch>(),
    'update',
    (state, action) => {
        const oldZone = findZone(action.id, state.building.activeZone);
        const undoAction = { ...action };
        Object.keys(undoAction).forEach((key: string) => {
            if (key !== 'type') {
                if (key === 'movementVector') {
                    const movementVectorBefore = undoAction[key];
                    undoAction[key] = movementVectorBefore
                        ? { x: -movementVectorBefore.x, y: -movementVectorBefore.y }
                        : null;
                } else if (oldZone?.[key]) {
                    undoAction[key] = oldZone[key];
                } else {
                    undoAction[key] = null;
                }
            }
        });
        return patchZone(undoAction);
    },
);

export const addZoneSuccess = createAction(
    '[Zone API Undoable] Add a zone in the topology (success)',
    props<{ zone: ZoneDetail }>(),
);

export const addZone = createUndoableAction(
    '[Zone API Undoable] Add a zone in the topology',
    props<{ zone?: ZoneDetail; selectRoot?: boolean; prototype?: Prototype }>(),
    'create',
    (_state, action) => {
        return ZoneActions.deleteZone({ zoneId: action.zone.id, force: false });
    },
    (_state, action) => {
        action.zone.parentId = action.zone.parent ? action.zone.parent.id : null;
        delete action.zone.parent;
        return action;
    },
    addZoneSuccess,
);

export const addSiblingSuccess = createAction(
    '[Zone API Undoable] Add a sibling in the topology (success)',
    props<{ zone: ZoneDetail }>(),
);

export const addSibling = createUndoableAction(
    '[Zone API Undoable] Add a sibling in the topology',
    props<{ zone?: ZoneDetail; selectRoot?: boolean; prototype?: Prototype }>(),
    'create',
    (_state, action) => {
        return ZoneActions.deleteZone({ zoneId: action.zone.id, force: false });
    },
    (_state, action) => {
        action.zone.parentId = action.zone.parent ? action.zone.parent.id : null;
        delete action.zone.parent;
        return action;
    },
    addSiblingSuccess,
);
