import { DatasheetDetail } from '@priva/next-model';

import { countOccurrences } from 'app/common/utils';
import { DatasheetStateContainer } from 'app/template/datasheet/state/datasheet.state';

export const selectDatasheetDetails = (state: DatasheetStateContainer) => state.datasheet.datasheets;

export const getActiveDatasheetDetails = (
    datasheets: DatasheetDetail[],
    activeDatasheetIds: string[],
): DatasheetDetail[] => {
    if (!datasheets) {
        return undefined;
    }
    const activeDatasheets = [];
    const occurrences = countOccurrences(activeDatasheetIds);
    activeDatasheetIds.forEach((id, index) => {
        const datasheet = datasheets.find((d) => d.id === id);
        if (datasheet) {
            const clonedDatasheet = JSON.parse(JSON.stringify(datasheet));
            clonedDatasheet.datasheetIndex = index;
            clonedDatasheet.datasheetNumber = occurrences[index];
            activeDatasheets.push(clonedDatasheet);
        }
    });
    return activeDatasheets;
};
