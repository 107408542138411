import {
    ConfigurationUploadStatus,
    ControllerProperties,
    ControllerProtocolSettings,
    ControllerRuntime,
    Element,
} from '@priva/next-model';

export interface ControllersStateContainer {
    controllers: ControllersState;
}

export interface ControllerConfigurationUploadStatus {
    controllerId: string;
    status: ConfigurationUploadStatus;
}

export interface ControllersState {
    controllers: Element[];
    controllersProtocolSettings: ControllerProtocolSettings[];
    controllersConfigurationUploadStatus: ControllerConfigurationUploadStatus[];
    controllerProperties: ControllerProperties[];
    controllerRuntimes: ControllerRuntime[];
    error?: string;
}

export const initialControllersState: ControllersState = {
    controllers: [],
    controllersProtocolSettings: [],
    controllersConfigurationUploadStatus: [],
    controllerProperties: [],
    controllerRuntimes: [],
    error: undefined,
};
