import { createAction, props } from '@ngrx/store';

import { Element, ElementBindingStatus } from '@priva/next-model';

export const resetElementsState = createAction('[Elements] Reset to init state');

export const getAllElementsInSolution = createAction('[Elements API] Get all elements in solution');
export const getAllElementsInSolutionSuccess = createAction(
    '[Elements API] Get all elements in solution (success)',
    props<{ elements: Element[] }>(),
);

export const getElementsInZone = createAction(
    '[Elements API] Get elements in zone',
    props<{ zoneId: string }>(),
);
export const getElementsInZoneSuccess = createAction(
    '[Elements API] Get elements in zone (success)',
    props<{ zoneId: string; elements: Element[] }>(),
);

export const getElementBindingStatesInZone = createAction(
    '[Elements API] Get elements binding states in zone',
    props<{ zoneId: string }>(),
);
export const getElementBindingStatesInZoneSuccess = createAction(
    '[Elements API] Get elements binding states in zone (success)',
    props<{ zoneId: string; elementBindingStatuses: ElementBindingStatus[] }>(),
);

export const updateElementsInState = createAction(
    '[Elements] Update elements in state',
    props<{ elements: Element[] }>(),
);
