import { createAction, props } from '@ngrx/store';

import { ChoiceList, DatasheetBinding, LogicalDeviceDefinition } from '@priva/next-model';

import { UpdateSystemComponentOption } from 'app/template/template.model';

export const updateNotSavedConfigurations = createAction(
    '[Configuration API] Update not saved configurations',
    props<{ systemComponentId: string; systemComponentOptions: UpdateSystemComponentOption[] }>(),
);

export const updateNotSavedConfigurationsSuccess = createAction(
    '[Configuration API] Update not saved configurations (success)',
    props<{ choiceList: ChoiceList }>(),
);

export const getInitialConfigurations = createAction(
    '[Configuration API] Get initial configurations',
    props<{ systemComponentId: string }>(),
);
export const getConfigurations = createAction(
    '[Configuration API] Get configurations',
    props<{ systemComponentId: string }>(),
);
export const getConfigurationsSuccess = createAction(
    '[Configuration API] Get configurations (success)',
    props<{ systemComponentId: string; choiceList: ChoiceList }>(),
);

export const updateConfigurations = createAction(
    '[Configuration API] Update configurations',
    props<{ systemComponentId: string; systemComponentOptions: UpdateSystemComponentOption[] }>(),
);

export const updateConfigurationsSuccess = createAction(
    '[Configuration API] Update configurations (success)',
    props<{ systemComponentId: string; choiceList: ChoiceList }>(),
);

export const applyConfigurations = createAction(
    '[Configuration API] Apply configurations',
    props<{ systemComponentId: string }>(),
);

export const applyConfigurationsSuccess = createAction(
    '[Configuration API] Apply configurations (success)',
    props<{ systemComponentId: string }>(),
);

export const getLogicalDeviceDefinitions = createAction(
    '[Configuration API] Get logical device definitions',
    props<{ systemComponentId: string; serializedState?: string }>(),
);

export const getLogicalDeviceDefinitionsSuccess = createAction(
    '[Configuration API] Get logical device definitions (success)',
    props<{ logicalDeviceDefinitions: LogicalDeviceDefinition[] }>(),
);

export const getDatasheetBindings = createAction(
    '[Configuration API] Get datasheet bindings',
    props<{ systemComponentId: string }>(),
);

export const getDatasheetBindingsSuccess = createAction(
    '[Configuration API] Get datasheet bindings (success)',
    props<{ datasheetBindings: DatasheetBinding[]; isChanged: boolean }>(),
);

export const applyDatasheetBindings = createAction(
    '[Configuration API] Apply datasheet bindings',
    props<{ systemComponentId: string }>(),
);

export const applyDatasheetBindingsSuccess = createAction(
    '[Configuration API] Apply datasheet bindings (success)',
    props<{ systemComponentId: string; datasheetBindings: DatasheetBinding[] }>(),
);

export const applyLogicalDeviceDefinitions = createAction(
    '[Configuration API] Apply logical device definition',
);

export const applyLogicalDeviceDefinitionSuccess = createAction(
    '[Configuration API] Apply logical device definition (success)',
);
