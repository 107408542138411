import { createAction, props } from '@ngrx/store';

import { Element, TerminalUnitModelType, Zone, ZoneDetail } from '@priva/next-model';

export const resetZoneState = createAction('[Zone] Reset to init state');

export const getZone = createAction('[Zone API] Get zone', props<{ zoneId: string }>());
export const getZoneSuccess = createAction('[Zone API] Get zone (success)', props<{ zone: ZoneDetail }>());

export const deleteZone = createAction('[Zone API] Delete zone', props<{ zoneId: string; force: boolean }>());
export const deleteZoneSuccess = createAction(
    '[Zone API] Delete zone (success)',
    props<{ zoneId: string }>(),
);

export const deleteZoneWithConfirmCheck = createAction(
    '[Zone API] Delete zone with confirm check',
    props<{ zoneId: string }>(),
);
export const deleteZoneWithConfirmCheckSuccess = createAction(
    '[Zone API] Delete zone with confirm check (success)',
    props<{ zone: ZoneDetail }>(),
);

export const duplicateZone = createAction(
    '[Zone API] Duplicate a zone in the topology',
    props<{ zoneId: string; name?: string }>(),
);
export const duplicateZoneSuccess = createAction(
    '[Zone API] Duplicate a zone in  the topology (success)',
    props<{ zone: ZoneDetail }>(),
);

export const groupZones = createAction(
    '[Zone API] Group segments into space',
    props<{ name?: string; zoneIds: string[] }>(),
);
export const groupZonesSuccess = createAction(
    '[Zone API] Group segments into space (success)',
    props<{ zone: ZoneDetail }>(),
);

export const ungroupZone = createAction(
    '[Zone API] Un group space',
    props<{ zone: Zone; parentZoneId: string }>(),
);
export const ungroupZoneSuccess = createAction(
    '[Zone API] Un group space (success)',
    props<{ parentZoneId: string }>(),
);

export const uploadBackgroundImage = createAction(
    '[Zone API] Upload background for a zone',
    props<{ zoneId: string; file: File }>(),
);
export const uploadBackgroundImageSuccess = createAction('[Zone API] Upload background for a zone (success)');

export const deleteFloorplanBackground = createAction(
    '[Zone API] Delete floorplan background',
    props<{ zoneId: string }>(),
);
export const deleteFloorplanBackgroundSuccess = createAction(
    '[Zone API] Delete floorplan background (success)',
);

export const getZoneBoundElements = createAction(
    '[Controllers API] Get zone bound elements',
    props<{ zoneId: string; types: TerminalUnitModelType[] }>(),
);
export const getZoneBoundElementsSuccess = createAction(
    '[Controllers API] Get zone bound elements (success)',
    props<{ zoneId: string; elements: Element[] }>(),
);
