import { createAction, props } from '@ngrx/store';

import { ControllerConfigurationInfo, ControllerProperties, Element, Firmware } from '@priva/next-model';

export const getControllerRuntime = createAction(
    '[Controller API] Get controller runtime',
    props<{ elementId: string }>(),
);
export const getControllerRuntimeSuccess = createAction(
    '[Controller API] Get controller runtime (success)',
    props<{ elementId: string; firmware: string; configuration: number }>(),
);
export const getControllerRuntimeFailed = createAction(
    '[Controller API] Get controller runtime (failure)',
    props<{ elementId: string; error: string }>(),
);

export const initControllerElement = createAction(
    '[Controller] Initialize a controller element',
    props<{ element: Element; controllerProperties: ControllerProperties }>(),
);
export const initControllerElementFailed = createAction(
    '[Controller] Initialize a controller element (failed)',
    props<{ error?: string }>(),
);

export const connectControllerElement = createAction(
    '[Controller] Connect a controller element',
    props<{
        element: Element;
        controllerProperties: ControllerProperties;
    }>(),
);

export const connectControllerElementFailed = createAction(
    '[Controller] Connect a controller element (failed)',
    props<{ error?: string }>(),
);

export const clearControllerElementError = createAction('[Controller] Clear controller element error');

export const updateControllerElement = createAction(
    '[Controller] Update a controller element',
    props<{
        element: Element;
        controllerProperties: ControllerProperties;
        firmware?: Firmware;
        configuration?: ControllerConfigurationInfo;
    }>(),
);

export const updateFirmwareOfElement = createAction(
    '[Controller] Update the firmware of an element',
    props<{
        element: Element;
        controllerProperties: ControllerProperties;
        firmware: Firmware;
        nextAction?: any;
    }>(),
);

export const updateFirmwareOfElementSuccess = createAction(
    '[Controller] Update the firmware of an element (success)',
    props<{
        element: Element;
        firmware: Firmware;
        controllerProperties: ControllerProperties;
        nextAction?: any;
    }>(),
);

export const updateFirmwareOfElementFailure = createAction(
    '[Controller] Update the firmware of an element (failure)',
    props<{ element: Element; error: string }>(),
);

export const updateConfigurationOfElement = createAction(
    '[Controller] Update the configuration of an element',
    props<{ element: Element }>(),
);
export const updateConfigurationOfElementSuccess = createAction(
    '[Controller] Update the configuration of an element (success)',
    props<{ element: Element; controllerProperties: ControllerProperties }>(),
);
export const updateConfigurationOfElementFailure = createAction(
    '[Controller] Update the configuration of an element (failure)',
    props<{ element: Element; error: string }>(),
);
