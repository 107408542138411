import {
    ConfigurationChoice,
    ConfigurationTab,
    DatasheetBinding,
    LogicalDeviceDefinition,
    SystemComponentCreate,
} from '@priva/next-model';

import { ValuesState } from '../../../../solution/common/datapoint-values/state';
import { ConnectivityState } from '../connectivity-configurations/state';

export interface ConfigurationStateContainer {
    configuration: ConfigurationState;
    connectivity: ConnectivityState;
    values: ValuesState;
}

export interface ConfigurationState {
    active: ConfigurationChoice[];
    activeTab: ConfigurationTab;
    updating: boolean;
    logicalDeviceDefinitions: LogicalDeviceDefinition[];
    dirtyLogicalDeviceDefinitions: string[];
    devicesNeeded: string[];
    datasheetBindings: DatasheetBinding[];
    isCommunicationChanged: boolean;
    newSystemComponent: SystemComponentCreate;
    stateChoiceList: string;
    isConfigurationChanged: boolean;
}

export const initialState: ConfigurationState = {
    active: [],
    activeTab: undefined,
    updating: false,
    logicalDeviceDefinitions: undefined,
    dirtyLogicalDeviceDefinitions: [],
    devicesNeeded: [],
    datasheetBindings: [],
    isCommunicationChanged: false,
    newSystemComponent: undefined,
    stateChoiceList: undefined,
    isConfigurationChanged: false,
};
