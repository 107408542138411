import { createAction, props } from '@ngrx/store';

import { ControllerProperties, ControllerProtocolSettings, Element } from '@priva/next-model';

import { ControllerConfigurationUploadStatus } from './controllers.state';

export const resetState = createAction('[Controllers] Reset to init state');

export const getAllControllers = createAction('[Controllers API] Get all controllers');
export const getAllControllersSuccess = createAction(
    '[Controllers API] Get all controllers (success)',
    props<{ controllers: Element[] }>(),
);

export const getAllControllersProtocolSettingsSuccess = createAction(
    '[Controllers API] Get all controllers protocol settings (success)',
    props<{ controllersProtocolSettings: ControllerProtocolSettings[] }>(),
);

export const getControllerProtocolSettings = createAction(
    '[Controllers API] Get controller protocol settings',
    props<{ controllerId: string }>(),
);
export const getControllerProtocolSettingsSuccess = createAction(
    '[Controllers API] Get controller protocol settings (success)',
    props<{ controllerProtocolSettings: ControllerProtocolSettings }>(),
);

export const refreshControllerProtocolSettings = createAction(
    '[Controllers] Refresh existing controller protocol settings',
);
export const updateControllerProtocolSettings = createAction(
    '[Controllers] Update controller protocol settings',
    props<{ controllerProtocolSettings: ControllerProtocolSettings[] }>(),
);

export const updateControllersAfterRemoveDevice = createAction(
    '[Controllers] Update controllers after remove device',
    props<{ controllerId: string }>(),
);

export const updateControllersAfterRemoveDevices = createAction(
    '[Controllers] Update controllers after remove devices',
    props<{ elements: Element[] }>(),
);

export const getControllerIfNeeded = createAction(
    '[Controllers] Get controller',
    props<{ controllerId: string }>(),
);

export const changeConfigurationUploadStatus = createAction(
    '[Controllers] Change controller configuration upload status',
    props<{ controllerUploadStatus: ControllerConfigurationUploadStatus }>(),
);

export const getAllControllerProperties = createAction(
    '[Controllers API] Get controller properties for all controllers',
);
export const getAllControllerPropertiesSuccess = createAction(
    '[Controllers API] Get controller properties for all controllers (success)',
    props<{ properties: ControllerProperties[] }>(),
);

export const getControllerProperties = createAction(
    '[Controllers API] Get controller properties',
    props<{ id: string }>(),
);
export const getControllerPropertiesSuccess = createAction(
    '[Controllers API] Get controller properties (success)',
    props<{ properties: ControllerProperties }>(),
);

export const updateControllerProperties = createAction(
    '[Controllers API] Update controller properties',
    props<{ properties: ControllerProperties }>(),
);
export const updateControllerPropertiesSuccess = createAction(
    '[Controllers API] Update controller properties (success)',
    props<{ properties: ControllerProperties }>(),
);
