import { DatapointCandidate, Device, Element, IdName, ProtocolType } from '@priva/next-model';

export interface ElementDetail extends Element {
    equipment?: IdName;
    protocolType?: ProtocolType;
    devices?: Device[];
    properties?: {
        serialNumber?: string;
        note?: string;

        firmware?: string;
        configuration?: string;

        ipAddress?: string;
        subnetMask?: string;
        defaultGateway?: string;
        nameServer1?: string;
        nameServer2?: string;
    };
}

export interface ElementCandidate extends IdName {
    target: Element | DatapointCandidate;
}

export interface ElementCandidateWithBreadcrumb extends ElementCandidate {
    breadcrumb: string;
    visible: boolean;
}

export function isDatapointCandidate(target: Element | DatapointCandidate): target is DatapointCandidate {
    return 'datapoint' in target;
}
