import { createAction, props } from '@ngrx/store';

import {
    ConfigurationTab,
    DatasheetBinding,
    DatasheetDetail,
    LogicalDeviceBinding,
    LogicalDeviceDefinition,
    LogicalDeviceExternalType,
    SystemComponent,
    SystemComponentCreate,
} from '@priva/next-model';

export const resetState = createAction('[Configuration] Reset to init state');

export const setActiveTab = createAction(
    '[Configuration] Set active tab',
    props<{ tab: ConfigurationTab }>(),
);

export const saveConfigurations = createAction(
    '[Configuration] Save configurations',
    props<{ systemComponentId?: string }>(),
);
export const discardChanges = createAction(
    '[Configuration] Discard all changes',
    props<{ systemComponent?: SystemComponent }>(),
);

export const setNewSystemComponent = createAction(
    '[Configuration] Set new system component (not saved)',
    props<{ newSystemComponent: SystemComponentCreate }>(),
);
export const applyNewSystemComponentSuccess = createAction(
    '[Configuration] New system component saved (success)',
);

export const setDatasheetBindings = createAction(
    '[Configuration] Set datasheet bindings',
    props<{ datasheetBindings: DatasheetBinding[]; isChanged: boolean }>(),
);

export const addDatasheetBinding = createAction(
    '[Configuration] Add datasheet binding',
    props<{
        logicalDeviceBinding: LogicalDeviceBinding;
        datasheet: DatasheetDetail;
    }>(),
);

export const updateDatasheetBinding = createAction(
    '[Configuration] Update datasheet binding',
    props<{
        logicalDeviceBinding: LogicalDeviceBinding;
        datasheet: DatasheetDetail;
    }>(),
);

export const removeDatasheetBinding = createAction(
    '[Configuration] Remove datasheet binding',
    props<{ logicalDeviceDefinitionId: string }>(),
);

export const updateExternalToggle = createAction(
    '[Configuration] Update external toggle',
    props<{ logicalDeviceDefinitionId: string; scope: LogicalDeviceExternalType }>(),
);

export const setDevicesNeeded = createAction(
    '[Configuration] Set devices needed',
    props<{ logicalDeviceDefinitions: LogicalDeviceDefinition[] }>(),
);
